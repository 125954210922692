:root {
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono',
    'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono', 'Droid Sans Mono',
    'Courier New', monospace;
  --color-main: #2CC09C;
  --color-text-main: #333;
  --color-text-sub: #999;
  --color-text-unpainted: #fff;
  --color-text-error: #f33;
  --color-bg-main: #fff;
  --color-bg-sub: #f3f3f3;
  --color-bg-code: #fafafa;
  --color-bg-painted: #333;
  --color-border-dark: #333;
  --color-border: #ddd;
  --color-border-light: #f3f3f3;
  --color-current: #eee;
  --color-button-primary: #333;
  --border-radius: 4px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: var(--color-text-main);
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', YuGothic, 'ヒラギノ角ゴ ProN W3',
    Hiragino Kaku Gothic ProN, Arial, 'メイリオ', Meiryo, sans-serif;
  line-height: 1.8;
}

code {
  font-family: menlo, inconsolata, monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

ol,
ul {
  list-style: none;
}
